(function ($, site, prodcat) {
  // eslint-disable-next-line complexity
  $(document).on('product.skuSelect', '.js-product', function (event, skuBaseId) {
    var $product = $(this);
    var $imageContainer = $('.js-product-quickshop__image', $(this));

    if (!$imageContainer.length) {
      return;
    }
    var prod = $product ? prodcat.data.getProduct($product.data('product-id')) : null;
    var sku = prodcat.data.getSku(skuBaseId);
    // check against current sku | avoid rendering twice
    var currentSku = $imageContainer.attr('data-sku-base-id');

    // console.log('currentSku: ' + currentSku + ' ~ ' + 'skuBaseId: ' + skuBaseId);
    if (!!currentSku && skuBaseId == currentSku) {
      return;
    }

    // Parse the SKU otherwise parse the defaultSku of the product or just the product image
    var data = sku ? sku : prod.defaultSku ? prod.defaultSku : prod;

    if ($product.data('disable-product-image-carousel') === 1) {
      if (data.LARGE_ALT_IMAGES) {
        delete data.LARGE_ALT_IMAGES;
      }
    }

    /* ------- Sku Images ------- */
    var product_image_quickshop = site.template.get({
      name: 'product_image_quickshop',
      data: data
    });
    var $imageActiveQS = $('.js-product-quickshop__carousel__slide.slick-active', $product);
    var indexImageQS = $imageActiveQS.attr('data-slick-index') || 0;

    $imageContainer
      .fadeTo(100, 0, 'linear', function () {
        $(this).html($(product_image_quickshop).html());
        // Refresh the slick slider if there is one
        Drupal.behaviors.productQuickshopImageV1.indexImage = indexImageQS;
        Drupal.behaviors.productQuickshopImageV1.attach($product);
      })
      .fadeTo(100, 1, 'linear');
  });

  Drupal.behaviors.productQuickshopImageV1 = {
    indexImage: false,
    setupCarousel: function ($container, indexQS) {
      var $images = $('.js-product-quickshop__image__carousel', $container);
      var $carousel = $('.js-product-quickshop__carousel', $images);
      var arrowsDiv = $('.js-carousel-controls', $container);
      var dotsDiv = $('.js-carousel-dots', $container);
      var settings = {
        slide: '.js-product-quickshop__carousel__slide',
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
        rows: 0,
        initialSlide: indexQS,
        // lazyload: 'ondemand',
        appendArrows: arrowsDiv,
        appendDots: dotsDiv
      };
      var init_default_carousel = function () {
        // Init this carousel with our settings
        $carousel.not('.slick-initialized').slick(settings);
        $carousel.slick('slickGoTo', indexQS);
        $carousel.slick('slickFilter', ':not(.hidden)');

        // Update the thumbnails to highlight the correct thumbnail
        $carousel.on('afterChange', function (event, slick, currentSlide) {
          var $thumbnail = $('.js-product-quickshop__thumbnail', slick.$slider.parents('.js-product-quickshop__image'));
          var $thumbnailItem = $('.js-product-quickshop__thumbnail__item', $thumbnail);

          $thumbnailItem.removeClass('active'); // Remove other active states
          $thumbnailItem.eq(currentSlide).addClass('active'); // Add the active state to this item
        });
      };
      var refresh_carousel = function () {
        $carousel.slick('slickFilter', ':not(.hidden)');
        $carousel.slick('setPosition');
      };

      init_default_carousel();

      $carousel.on('refresh', refresh_carousel);
      // thumbnails
      var $thumbnail = $('.js-product-quickshop__thumbnail', $container);

      $thumbnail.each(function () {
        var $thisFormatter = $(this);
        var $thumbnailItem = $('.js-product-quickshop__thumbnail__item', $thisFormatter);

        $thumbnailItem.first().addClass('active'); // Set the first as active on load
        var $thumbnailImage = $('.js-product-quickshop__thumbnail__image', $thumbnailItem);

        $thumbnailImage.on('click', function () {
          var currentSlide = $thumbnailItem.index($(this).parents($thumbnailItem));

          $carousel.slick('slickGoTo', currentSlide);
          $thumbnailItem.removeClass('active'); // Remove other active states
          $thumbnailItem.eq(currentSlide).addClass('active'); // Add the active state to this item
        });
      });
    },
    attach: function (context) {
      var self = this;
      var $containers = $('.js-product-quickshop__image', context);
      var indexQS = this.indexImage || 0;

      $containers.each(function () {
        var $container = $(this);
        var $product = $container.closest('.js-product');

        if ($product.data('disable-product-image-carousel') !== 1) {
          self.setupCarousel($container, indexQS);
        }

        var sppLink = $container.parents('.product-quickshop').find('.js-product-name').attr('href');

        $container.find('.js-spp-link').attr('href', sppLink);
      });
    }
  };
})(jQuery, window.site || {}, window.prodcat || {});
